<template>
  <div class="video-menu m-t-md flex items-center">
    <div
      v-for="(item, index) in videoMenuList"
      :key="item.id"
      :class="[
        'video-menu-item flex flex-column justify-center items-center pointer',
        {
          'video-menu-no': item.num === '',
          'video-menu-yes': !!item.num,
          current: selectOptions.includes(index),
        },
      ]"
      @click="selectHandle(index)"
    >
      <template>
        <span class="block ht f20 weight" v-if="item.num !== ''">{{
          item.num
        }}</span>
        <span class="block ht" v-else></span>
      </template>
      <span class="f12">{{ item.address }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoMenu',
  props: {
    videoMenuList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      selectOptions: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    selectHandle(index) {
      if (this.selectOptions.includes(index)) {
        this.$set(this.selectOptions, index, '');
      } else {
        this.$set(this.selectOptions, index, index);
      }
    },
  },
};
</script>

<style scoped>
.video-menu {
  flex-wrap: wrap;
}
.video-menu-item {
  width: 94px;
  height: 77px;
  flex-shrink: 0;
}
.video-menu-item span.ht {
  height: 38%;
}
.video-menu-item span.f12 {
  color: rgba(134, 179, 233, 0.5);
}
.video-menu-no {
  background: url('../../../assets/home/video-no.png');
  background-size: 100% 100%;
}
.video-menu-yes {
  background: url('../../../assets/home/video-yes.png');
  background-size: 100% 100%;
}
.video-menu-item.current {
  background: url('../../../assets/home/video-on.png');
  background-size: 100% 100%;
  color: rgba(255, 205, 161, 1);
}
.video-menu-item.current span.f12 {
  color: rgba(255, 205, 161, 0.5);
}
</style>
