var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"video-detail m-t-md"},[_c('vue-scroll',{staticStyle:{"height":"100%"},attrs:{"ops":_vm.$root.scrollOpsY}},[_c('div',{staticClass:"on-con relative"},[_c('span',{staticClass:"cor t-lf absolute"}),_c('span',{staticClass:"cor t-rf absolute"}),_c('span',{staticClass:"cor b-lf absolute"}),_c('span',{staticClass:"cor b-rf absolute"}),_c('div',{staticClass:"p-h-md flex"},[_c('div',{staticClass:"v-d-l m-r-md"},[_c('ul',{staticClass:"v-d-list"},_vm._l(([
                0,
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10,
                11,
                12,
                13,
                14,
                15,
                16,
                17 ]),function(item){return _c('li',{key:item,staticClass:"flex items-center p-w-xs text-lightblue pointer"},[_c('span',{staticClass:"sort"},[_vm._v(_vm._s(item + 1)+".")]),_c('span',{staticClass:"ellipsis flex-one"},[_vm._v("G1京哈高速辽宁省葫芦岛市K500+200（九华山隧道隧道隧道隧道）")])])}),0)]),_c('div',{staticClass:"flex-one flex"},[_c('div',{staticClass:"bd-r bd-b bd-l bd-t flex-one full-height"}),_c('div',{staticClass:"operation m-l-md"},[_c('i',{staticClass:"icon-cut block m-b-sm pointer"}),_c('i',{staticClass:"icon-video block m-b-sm pointer"}),_c('i',{staticClass:"icon-direction block m-b-sm pointer"}),_c('i',{staticClass:"icon-screen block pointer"})])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }