<template>
  <div class="bg-blue full-height text-lightblue p-md">
    <back url="/" />
    <video-menu :videoMenuList="videoMenu" />
    <video-detail />
  </div>
</template>

<script>
import Back from '@/components/Back';
import VideoMenu from './components/VideoMenu';
import VideoDetail from './components/VideoDetail';
export default {
  name: 'OutProvinceVideo',
  data() {
    return {
      videoMenu: [
        {
          id: 0,
          num: 10,
          address: '北京',
        },
        {
          id: 1,
          num: '',
          address: '天津',
        },
        {
          id: 2,
          num: '',
          address: '河北',
        },
        {
          id: 3,
          num: 10,
          address: '辽宁',
        },
        {
          id: 4,
          num: 10,
          address: '吉林',
        },
        {
          id: 5,
          num: 10,
          address: '黑龙江',
        },
        {
          id: 6,
          num: 10,
          address: '北京',
        },
        {
          id: 7,
          num: '',
          address: '天津',
        },
        {
          id: 8,
          num: '',
          address: '河北',
        },
        {
          id: 9,
          num: 10,
          address: '辽宁',
        },
        {
          id: 10,
          num: 10,
          address: '吉林',
        },
        {
          id: 11,
          num: 10,
          address: '黑龙江',
        },
        {
          id: 12,
          num: 10,
          address: '北京',
        },
        {
          id: 13,
          num: '',
          address: '天津',
        },
        {
          id: 14,
          num: '',
          address: '河北',
        },
        {
          id: 15,
          num: 10,
          address: '辽宁',
        },
        {
          id: 16,
          num: 10,
          address: '吉林',
        },
        {
          id: 17,
          num: 10,
          address: '黑龙江',
        },
      ],
    };
  },
  components: {
    Back,
    VideoMenu,
    VideoDetail,
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped>
.bg-blue {
  background: #162d51;
}
</style>
