<template>
  <div class="video-detail m-t-md">
    <vue-scroll :ops="$root.scrollOpsY" style="height: 100%">
      <div class="on-con relative">
        <span class="cor t-lf absolute"></span>
        <span class="cor t-rf absolute"></span>
        <span class="cor b-lf absolute"></span>
        <span class="cor b-rf absolute"></span>
        <div class="p-h-md flex">
          <div class="v-d-l m-r-md">
            <ul class="v-d-list">
              <li
                v-for="item in [
                  0,
                  1,
                  2,
                  3,
                  4,
                  5,
                  6,
                  7,
                  8,
                  9,
                  10,
                  11,
                  12,
                  13,
                  14,
                  15,
                  16,
                  17,
                ]"
                :key="item"
                class="flex items-center p-w-xs text-lightblue pointer"
              >
                <span class="sort">{{ item + 1 }}.</span>
                <span class="ellipsis flex-one"
                  >G1京哈高速辽宁省葫芦岛市K500+200（九华山隧道隧道隧道隧道）</span
                >
              </li>
            </ul>
          </div>
          <div class="flex-one flex">
            <div class="bd-r bd-b bd-l bd-t flex-one full-height"></div>
            <div class="operation m-l-md">
              <i class="icon-cut block m-b-sm pointer"></i>
              <i class="icon-video block m-b-sm pointer"></i>
              <i class="icon-direction block m-b-sm pointer"></i>
              <i class="icon-screen block pointer"></i>
            </div>
          </div>
        </div>
      </div>
    </vue-scroll>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped>
.video-detail {
  height: calc(100% - 204px);
}
.v-d-l {
  width: 394px;
}
.sort {
  width: 24px;
}
.v-d-list li {
  line-height: 34px;
}
.v-d-list li:hover,
.v-d-list li.current {
  background: rgba(31, 57, 97, 0.4);
}
.operation {
  width: 125px;
}
.operation i {
  width: 48px;
  height: 48px;
  background-size: 100%;
  background-repeat: no-repeat;
}
.icon-cut {
  background-image: url('../../../assets/home/cut.png');
}
.icon-cut:hover {
  background-image: url('../../../assets/home/cut-on.png');
}
.icon-video {
  background-image: url('../../../assets/home/cart.png');
}
.icon-video:hover {
  background-image: url('../../../assets/home/cart-on.png');
}
.icon-direction {
  background-image: url('../../../assets/home/direction.png');
}
.icon-direction:hover {
  background-image: url('../../../assets/home/direction-on.png');
}
.icon-screen {
  background-image: url('../../../assets/home/fullscreen.png');
}
.icon-screen:hover {
  background-image: url('../../../assets/home/fullscreen-on.png');
}
</style>
